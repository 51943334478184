/* Provide sufficient contrast against white background */

body {
    font-size: 90%;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.vertical-timeline::before {
    background-color: #060606;
}

.vertical-timeline-element-content {
    color: black;
}

.vertical-timeline-element--work .vertical-timeline-element-content {
    border-top: 3px solid #2196f3;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.vertical-timeline-element--education .vertical-timeline-element-content {
    border-top: 3px solid #e91e63;
}

.vertical-timeline-element-date {
    color: #333;
}

.vertical-timeline--two-columns .vertical-timeline-element-content {
    padding: 0;
}

.float-filters {
    position: absolute;
    right: 0;
}

button.disabled {
    cursor: not-allowed;
}

.filters .col-1,
.filters .col-2,
.filters .col-3,
.filters .col-4,
.filters .col-5,
.filters .col-6,
.filters .col-7,
.filters .col-8,
.filters .col-9,
.filters .col-10,
.filters .col-11,
.filters .col-12,
.filters .col,
.filters .col-auto,
.filters .col-sm-1,
.filters .col-sm-2,
.filters .col-sm-3,
.filters .col-sm-4,
.filters .col-sm-5,
.filters .col-sm-6,
.filters .col-sm-7,
.filters .col-sm-8,
.filters .col-sm-9,
.filters .col-sm-10,
.filters .col-sm-11,
.filters .col-sm-12,
.filters .col-sm,
.filters .col-sm-auto,
.filters .col-md-1,
.filters .col-md-2,
.filters .col-md-3,
.filters .col-md-4,
.filters .col-md-5,
.filters .col-md-6,
.filters .col-md-7,
.filters .col-md-8,
.filters .col-md-9,
.filters .col-md-10,
.filters .col-md-11,
.filters .col-md-12,
.filters .col-md,
.filters .col-md-auto,
.filters .col-lg-1,
.filters .col-lg-2,
.filters .col-lg-3,
.filters .col-lg-4,
.filters .col-lg-5,
.filters .col-lg-6,
.filters .col-lg-7,
.filters .col-lg-8,
.filters .col-lg-9,
.filters .col-lg-10,
.filters .col-lg-11,
.filters .col-lg-12,
.filters .col-lg,
.filters .col-lg-auto,
.filters .col-xl-1,
.filters .col-xl-2,
.filters .col-xl-3,
.filters .col-xl-4,
.filters .col-xl-5,
.filters .col-xl-6,
.filters .col-xl-7,
.filters .col-xl-8,
.filters .col-xl-9,
.filters .col-xl-10,
.filters .col-xl-11,
.filters .col-xl-12,
.filters .col-xl,
.filters .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
}

.filters .row {
    margin: 0 -10px 10px;
}

.filters label,
.filters .form-group {
    margin-bottom: 0;
}

.filters .pl-4,
.filters .px-4 {
    padding-left: 10px !important;
}

.pagination div:first-child {
    padding-top: 10px;
}

.slide-item.active {
    color: #fff;
    background-color: #1b6ec2;
}

.slide-item .slide-id {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.slide-item .slide-header {
    width: calc(100% - 25px);
}

.case-block {
    column-gap: 20px;
}

.case-block .list-group-horizontal .list-group-item+.list-group-item.active {
    margin-left: initial;
    border-left-width: initial;
}

.case-block-stainings {
    column-gap: 10px;
    row-gap: 10px;
}

.case-block-stainings,
.macroscopic-images,
.slide-item .slide-thumbnail svg {
    flex-grow: 1;
}

.case-block-stainings li {
    width: 231px;
}

.recipient-contents {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.case-block {
    align-self: baseline;
    flex-grow: 0;
    width: auto;
    max-width: 1122px;
    flex-shrink: 0;
    background-color: #f7f7f7;
    border: 1px #ddd solid;
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
}

.case-block .title {
    width: 140px;
    flex: 0 0 auto;
}

.cases-table thead {
    position: sticky;
    inset-block-start: 0;
    max-height: 50px;
    top: 0px;
}

.cases-table {
    max-height: 100%;
}

.h-table {
    height: calc(100% - 50px);
}

.cases-table th,
.cases-table td {
    overflow: hidden;
    text-overflow: ellipsis;
}

.macro-item {
    width: 150px;
    height: 150px;
    text-align: center;
    padding: 0;
    margin: 5px;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    border: 1px #ddd solid;
    border-radius: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.pma-ui-gallery.grid li {
    width: 100% !important;
}

.pma-ui-gallery.grid li a {
    width: auto;
    height: auto !important;
}

.caw-slide-viewer .ol-custom-control.ne {
    display: flex;
}

.actions-column {
    max-width: 50px;
}

.pma-ui-gallery .barcode {
    width: 67px !important;
}

.status-dropdown .dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 19px;
}

.col-1-5 {
    flex: 0 0 12.666667%;
    max-width: 12.666667%;
}

.cases-main {
    font-size: 90%;
}

.cases-main h4 {
    font-size: 1.35rem;
}

.cases-main .btn {
    font-size: 0.9rem;
}

.cases-main .form-control::placeholder {
    font-size: 90%;
}

/* 
.react-bootstrap-table table.pims-table {
    table-layout: auto;
} */

.slidesgallery.pma-ui-gallery.grid>ul>li a {
    display: flex;
    flex-wrap: wrap;
}

.slidesgallery.pma-ui-gallery.grid>ul>li a span {
    flex: 0 0 100%;
}

.slidesgallery.pma-ui-gallery.grid>ul>li a div {
    flex: 1 1 auto;
    order: 1;
    background-size: contain;
}

.slidesgallery.pma-ui-gallery.grid>ul>li a img {
    position: initial;
    flex: 1 0 auto;
    height: auto;
    order: 0;
    object-fit: contain;
}

.ol-control button.disabled {
    color: #6c757d !important;
}

.ol-control button.disabled:hover {
    background-color: inherit !important;
}

.ol-control button {
    line-height: 2px !important;
}

.ol-custom-control button.active {
    background-color: rgba(0, 60, 136, 0.5);
}

.pma-ui-viewport-container .ol-custom-control.ne button,
.pma-ui-viewport-container .ol-full-screen button {
    padding-top: 15px;
    padding-bottom: 15px;
}

header>nav {
    /* background-color: orange !important; */
}

div.ui-block {
    position: fixed;
    top: 57px;
    bottom: 0px;
    right: 0;
    left: 16%;
    background: rgba(250, 250, 250, 0.5);
    z-index: 9999;
}

.button-fullscreen {
    display: flex !important;
    align-items: center;
}

.dropdown-input {
    display: inline-block;
    width: 310px;
}

.alertActions {
    position: absolute;
    bottom: 10px;
    left: 650px;
    width: 700px;
    text-align: center;
}

.tabPointers {
    cursor: pointer;
}

.cardHeight {
    height: 100%;
}

.cardScroll {
    overflow: auto;
}

.ui-loadingBlock {
    position: fixed;
    top: 57px;
    bottom: 0px;
    right: 50%;
    left: 0;
    background: rgba(250, 250, 250, 0.5);
}

#queryAlert {
    position: fixed;
    width: 400px;
    text-align: center;
    left: 750px;
    bottom: 500px;
}